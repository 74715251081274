<template>
<div>
  <ul class="tab-group">
    <li class="nav-item" v-if="user.is_vessel === false">
      <router-link class="nav-link" :to="{ name: 'VesselAuditRecordList'}">
        <span>Vessel Audit Record</span>
      </router-link>
    </li>
    <li class="nav-item" v-if="user.is_vessel === false && isAllowed('vessel-ia.appointments.vessel-audit-due.menu')">
      <router-link class="nav-link" :to="{ name: 'VesselAuditDueByVesselId' }">
        <span>Vessel Audit Due</span>
      </router-link>
    </li>
  </ul>
  <div class="col-12 pl-0">
    <router-view/>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  name : 'VesselAuditRecordMain',
  mixins : [permissionMixin],
  computed : {
    ...mapGetters([
      'user'
    ])
  }
}
</script>

<style scoped>
.tab-group {
  display: flex;
  width: 100%;
  margin-bottom: 0;
}

.tab-group > li {
  margin: .5rem 0;
  border-radius: 5px;
  list-style-type: none;

  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;

  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}

#ModelNav {
  padding: 0;

  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;

  * {
    color: #d3313c;
  }
}
</style>
